<template>
 <div class="container-fluid" style="overflow-x: hidden;">
    <div class="row" style="min-height: 100vh;">
      <div class="col-lg-5 login-side-col bg-primary">
        <div class="row h-100">
          <div class="col-12 align-self-center">
            <img class="my-auto align-self-center" :src="require('@/assets/images/logo-ae-w-alt.png')" />
          </div>
        </div>
      </div>
      <div class="col-lg-7 align-self-center h-100" style="">
          <div clas="row justify-content-center">
            <div class="col-12 text-center" v-if="status==='ACTIVATING'">
                <div class="lds-dual-ring"></div>
            </div>
            <div class="col-12 text-center" v-else-if="status==='ACTIVATION_OK'">
                <h3>Attivazione completata</h3>
                <p class="text-muted">
                    Il tuo account AdaptaEasy Manager è stato attivato con successo.
                </p>
                <div class="text-center">
                    <router-link to="/forgot-password" class="btn btn-primary">
                        Reimposta la tua password
                    </router-link>
                </div>
            </div>
            <div class="col-12 text-center" v-else-if="status==='ACTIVATION_FAIL'">
                <h3>Attivazione non riuscita</h3>
                <p class="text-muted" v-if="errorType==='INVALID_LINK'">
                    Questo link di attivazione è scaduto o invalido.
                </p>
                <p class="text-muted" v-if="errorType==='GENERAL_ERROR'">
                    Non è stato possibile eseguire l'attivazione a causa di un errore
                </p>
                <div class="text-center">
                    <router-link to="/login" class="btn btn-primary">
                        Vai al Login
                    </router-link>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api.js';

export default {
    name: 'AccountActivation',
    data() {
        return {
            status: 'ACTIVATING',
            uuid: this.$route.params.uuid,
            token: this.$route.params.token,
            errorType: "",
        }
    },
    methods: {
        async tryActivate() {
            this.status = 'ACTIVATING';
            try {
                const resp = await this.axios.post(
                    API.activateUser.path(),
                    {
                        uid: this.uuid,
                        token: this.token,
                    }
                );
                if (resp.status === 204) {
                    this.status = 'ACTIVATION_OK';
                }
            } catch (e) {
                this.status = 'ACTIVATION_FAIL';
                if (e.response.status === 400) {
                    this.errorType = "INVALID_LINK";
                } else if(e.response.status === 403) {
                    this.errorType = "INVALID_LINK";
                } else {
                    this.errorType = "GENERAL ERROR";
                }
            }
        },
    },
    mounted() {
        if (this.$auth.check()) {
            this.$auth.logout();
        }
        this.tryActivate();
    }
}
</script>
